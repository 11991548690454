@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.app-header {
  background: $green;

  .content {
    max-width: $content-max-width;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

   ul {
     display: flex;
     list-style: none;

     li {
       margin-right: 15px;

     }
   }

   .user-menu {
     display: flex;
   }
}