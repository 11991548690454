@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-sub-capability {
  max-width: $content-max-width;
  margin: 0 auto;

  .content {

    .evidences {
      border: 1px solid;
    }
  }
}