$whitish: #FFF;
$green: #6AFDEF;
$black: #001b18;
$grey: #747777;
$lighter-green: #f1f9f8;
$light-grey: #f6f8f8;
$red: red;
$blue-violet: #4440db;
$blue-cream: #dbf0fa;

/* Imbue Palette */
$black:             #1f2430;
$grey-dark:         #51596c;
$indigo:            #4440db;
$blue-acid:         #66f0e3;
$blue-cream-dark:   #c2e0e8;
$blue-cream:        #dbf0fa;
$grey-cream:        #f5f7fa;