.notifications {
  background: yellow;
  padding: 15px;
  position: fixed;
  top: 50px;
  right: 0;

  .notification {
    border-bottom: 1px solid;
    margin-bottom: 15px;
    display: flex;
    .notification-title {

    }
    .notification-date {

    }
  }
}