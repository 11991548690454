@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-home {
  max-width: $content-max-width;
  margin: 0 auto;

  .content {

    
  }
}