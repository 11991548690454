@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.page-capability {
  max-width: $content-max-width;
  margin: 0 auto;

  .content {

    .capabilities-list {
      
      .sub-capability {
        border: 1px solid;
        margin: 15px;
        padding: 15px;
      }
    }
  }
}