.activity-stream {
  .title {
    cursor: pointer;
  }

  .activities {
    display: none;

    &.open {
      display: block;
    }
  }
  .activity {
    border-bottom: 1px solid;
    background: green;
    color: white;
    padding: 15px;

    .user-image {
      img {
        width: 30px;
      }
    }
  }

  .activity-comment {
    .text {
      padding: 15px;
      background: white;
      color: black;
    }
  }
}