@import '../../styles/layout.scss';
@import '../../styles/colors.scss';

.user-box {
  border: 1px solid;
  background: white;
  margin: 15px;
  position: relative;
  cursor: pointer;

  img {
    width: 30px;
  }

  .user-notifications {
    background: red;
    color: white;
    font-size: 12px;
    padding: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}